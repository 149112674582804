<template>
    <div>
        <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path:'/illnessManage' }">疾病管理</el-breadcrumb-item>
            <el-breadcrumb-item>紧急患者管理</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="add">
            <!-- 搜索患者： -->
            <el-input placeholder="请输入姓名或电话号或id" size="small" clearable prefix-icon="el-icon-search" v-model="search"
                style="width: 210px;">
            </el-input>
            <el-button type="primary" size="small" style="margin-left:10px" @click="query">查询</el-button>
        </div>
        <el-table :data="list" border style="width: 100%">
            <el-table-column prop="Cust_ID" label="用户id"></el-table-column>
            <el-table-column prop="Cust_Name" label="姓名"></el-table-column>
            <el-table-column label="年龄" prop="Cust_Age"></el-table-column>
            <el-table-column label="性别" prop="Cust_Sex">
                <template slot-scope="scope">
                    {{ scope.row.Cust_Sex == 0?'男':'女'}}
                </template>
            </el-table-column>
            <el-table-column label="疾病" prop="des"></el-table-column>
            <el-table-column width="220">
                <template slot-scope="scope">
                    <div class="opt">
                         <el-button size="mini" type="primary" icon="el-icon-edit" @click="look(scope.row)">编辑 </el-button>
                        <el-button size="mini" type="danger" icon="el-icon-warning" @click="cancelEmergency(scope.row)">取消紧急 </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p>
        <el-dialog title="患者详情" :visible.sync="dialogFormVisible" width="50%">
            <el-form v-model="form" class="tcform" label-width="170px" style="text-align: left;">
                <el-form-item label="用户id：">
                    <span>{{form.Cust_ID}}</span>
                </el-form-item>
                <el-form-item label="姓名：">
                    <span>{{form.Cust_Name}}</span>
                </el-form-item>
                <el-form-item label="年龄：">
                    <span>{{form.Cust_Age}}</span>
                </el-form-item>
                <el-form-item label="性别：">
                    <span>{{form.Cust_Sex == 0?'男':'女'}}</span>
                </el-form-item>
                <!-- <el-form-item label="疾病：">
                    <span>{{bindillname}}</span>
                </el-form-item> -->
                <el-form-item label="排序:">
                    <el-input v-model="sort"></el-input>
                </el-form-item>
                <el-form-item label="疾病名称:">
                    <el-input v-model="des"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button v-if="isnew" type="primary" @click="setEmergency(form,'')">设置紧急</el-button>
                <el-button v-else @click="setEmergency(form,'save')">保存修改</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        inject: ['reload'],
        data() {
            return {
                list: [],
                current: 1,//默认显示第几页
                totalCount: 0,// 总条数，根据接口获取数据长度
                pageSizes: [20],// 个数选择器（可修改）
                PageSize: 20,// 默认每页显示的条数（可修改）
                value: false,
                // cust_id: '',
                search: '',
                dialogFormVisible: false,
                form: {
                    Cust_ID: '',
                    Cust_Name: '',
                    Cust_Age: '',
                    Cust_Sex: '',
                    MWH_illness: ''
                },
                sort: 0,
                isnew: true,
                des:'',
            }
        },
        mounted() {
            this.getcustlist(1);
        },
        methods: {
            handleSizeChange() { },
            handleCurrentChange(currentPage) {
                this.getcustlist(currentPage);
            },
            getcustlist(page) {
                this.axios.get("/gu/get_gu_cust_by_priority?page=" + page + "&size=" + this.PageSize)
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.totalCount = res.data.count;
                            this.list = res.data.result;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            async query() {
                const projectObj = await this.cha();
                const projectObj1 = await this.custdetail(projectObj.Cust_ID)
                this.bindillname = projectObj1;
            },
            cha() {
                if(this.search == ''){
                    this.$message({
                                type: 'error',
                                message: '请输入姓名或电话号或ID'
                            });
                }else{
                    const projectObj = this.axios.post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.search }))
                    .then((res) => {
                        if (res.data.code == 0) {
                            let arr = res.data.result[0];
                            this.form.Cust_ID = arr.Cust_ID;
                            this.form.Cust_Name = arr.Cust_Name;
                            this.form.Cust_Age = arr.Cust_Age;
                            this.form.Cust_Sex = arr.Cust_Sex;
                            this.form.MWH_illness = arr.MWH_illness;
                            this.dialogFormVisible = true;
                            return res.data.result[0];
                        } else {
                            this.$message({
                                type: 'error',
                                message: '没有查到该患者'
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                    return projectObj;
                }
            },
            custdetail(Cust_ID) {
                let str = '';
                const projectObj = this.axios.get("/gu/get_gu_ill_lib_cust_by_cust?cust_id=" + Cust_ID)
                    .then((res) => {
                        if (res.data.code == 0) {
                            let array = res.data.result;
                            if (res.data.result.length > 1) {
                                for (let i = 0; i < array.length; i++) {
                                    const element = array[i];
                                    str += element.name + '，'
                                }
                                str = str.substring(0, str.length - 1);
                            } else {
                                str = res.data.result[0].name;
                            }
                            return str;
                        } else {
                            console.log(res)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                return projectObj;
            },
            //设置紧急
            setEmergency(row, type) {
                let url, str;
                if (type == '') {
                    url = "/gu/insert_gu_cust_priority";
                    str = "设置成功"
                } else {
                    url = "/gu/update_gu_cust_priority";
                    str = "保存成功"
                }
                this.axios.post(url, this.qs.stringify({ x_cust_id: row.Cust_ID, sort: this.sort ,des:this.des}))
                    .then((res) => {
                        console.log(res)
                        if (res.data.code == 0) {
                            this.$message({
                                type: 'success',
                                message: str
                            });
                            this.dialogFormVisible = false;
                            this.form = '';
                            this.sort = '';
                            this.reload();
                            this.isnew = true;
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },
            look(row) {
                this.dialogFormVisible = true;
                this.isnew = false;
                this.sort = row.sort;
                this.form.Cust_ID = row.Cust_ID;
                this.form.Cust_Name = row.Cust_Name;
                this.form.Cust_Age = row.Cust_Age;
                this.form.Cust_Sex = row.Cust_Sex;
                this.form.MWH_illness = row.ill_name;
                this.des = row.des;
            },
            //取消紧急
            cancelEmergency(row) {
                this.axios.post("/gu/delete_gu_cust_priority", this.qs.stringify({ x_cust_id: row.Cust_ID }))
                    .then((res) => {
                        if (res.data.code == 0) {
                            this.$message({
                                type: 'success',
                                message: '取消成功'
                            });
                            this.reload();
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    }
</script>
<style>
    .Bdh {
        height: 50px;
        width: 100%;
        line-height: 40px;
    }

    .add {
        width: 100%;
        height: 50px;
        text-align: left;
    }

    .opt a {
        cursor: pointer;
        color: green;
        margin-right: 20px;
        display: inline-block;
    }
</style>